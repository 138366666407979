var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"how-work-header"},[_vm._m(0),_c('div',[_vm._m(1),_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.message)+" ")])])])]),_c('div',{staticClass:"how-work-header m-top-10"},[_vm._m(2),_c('div',[_vm._m(3),_vm._m(4),_vm._m(5),_vm._l((_vm.features),function(feature,i){return _c('div',{key:i,staticClass:"feature-list"},[_c('div',{staticStyle:{"justify-content":"left"}},[_c('span',[_vm._v(_vm._s(feature.text))])]),_c('div',[(feature.campaingn)?_c('span',{staticClass:"material-symbols-outlined check-color"},[_vm._v("check")]):_vm._e()]),_c('div',{staticStyle:{"justify-content":"right"}},[(feature.chat)?_c('span',{staticClass:"material-symbols-outlined check-color"},[_vm._v("check")]):_vm._e()])])})],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"how-work-info"},[_c('span',{staticClass:"material-symbols-outlined"},[_vm._v("info")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"how-work-info-title"},[_c('strong',[_vm._v("Como Funciona")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"how-work-info"},[_c('span',{staticClass:"material-symbols-outlined"},[_vm._v("info")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"how-work-info-title"},[_c('strong',[_vm._v("Recursos ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v(" O suporte a recursos varia de acordo com o canal. Verifique a disponibilidade de recursos abaixo. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-list border-bottom-header"},[_c('div',{staticStyle:{"justify-content":"left","font-size":"15px"}},[_c('span',[_vm._v("Recurso")])]),_c('div',{staticStyle:{"font-size":"15px"}},[_c('span',[_vm._v("Campanhas")])]),_c('div',{staticStyle:{"justify-content":"right","font-size":"15px"}},[_c('span',[_vm._v("Chat")])])])
}]

export { render, staticRenderFns }